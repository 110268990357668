<template>
  <v-card class="rounded-card2">
    <v-img :src="game.thumb" class="white--text align-end" aspect-ratio="1"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)">
    </v-img>
  </v-card>
</template>

<script>
export default {
  name: 'GameCard',
  props: ['game'],

  data: () => ({
  }),
}
</script>


<style scoped>
.rounded-card2 {
  border-radius: 15px;
  border: 3px solid #ccc;
}
</style>