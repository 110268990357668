import { getTimezoneOffset } from 'date-fns-tz';

const config = {
  // server: 'http://209.97.165.53',    // dev
  // server: 'http://192.168.1.3:5001',    // local dev
  infoserver: "https://www.lightsgames.com/logs?",
  blacklist: [
    "amazon",
    "linode",
    "digitalocean",
    "digital ocean",
    "aliyun",
    "alibaba",
    "taobao",
    "tencent",
    "tinmok",
    "choopa",
    "huawei",
    "performive",
    "gthost",
    "contabo"
  ],
  langList: ["en", "de", "fr", "es", "pt", "hi", "zh"],
  countryList: ["us", "de", "fr", "gb", "ca", "br", "mx", "in", "id", "co", "es", "sg"],
}



async function fetchWithTimeout(
  resource,
  options = { referrerPolicy: 'no-referrer' }
) {
  const { timeout = 8000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(resource, {
    ...options,
    signal: controller.signal
  });
  clearTimeout(id);
  return response;
}

async function getIpFromIpInfo() {

  try {
    let ipRequest = await fetchWithTimeout('https://ipinfo.io/json');
    if (ipRequest.status == 200) {
      return await ipRequest.json();
    }
  } catch (err) {
    console.log(err);
  }
  return null;
}



function sendInfo(url) {
  fetch(url);
  return;
}


async function verify() {
  // let bundle = adConfig.bundle;
  // let os = adConfig.os;
  // let ipInfo = this.ipInfo;
  let cid = 'test';
  let bundle = 'lightsgames.com';
  let ipInfo = await getIpFromIpInfo();

  let url = `${config.infoserver}&cid=${cid}&bundle=${bundle}`;


  // get width & height of current device
  let width = window.screen.availWidth;
  let height = window.screen.availHeight;
  // window.top.location.href
  let top = "";
  try{
    top = 
    (window.location != window.parent.location)
    ? document.referrer
    : document.location.href;
  } catch( err) {
    console.error(err);
  }
  let hidden = document.hidden;
  let visibility = document.visibilityState;

  // get window visiable size 
  let visiable = `${window.innerWidth}x${window.innerHeight}`;
  
  sendInfo(url + `&cause=-1&size=${width}x${height}&visible=${visiable}&hidden=${hidden}&visibility=${visibility}&top=${top}`);

  // cause 0: check ip match
  // verifyDisableBit bit : 0  
  if (!ipInfo) {
    // this.pass = false;
    console.log('IP Not Found, Quit');
    // send block info
    url += `&cause=0`;
    console.log(url);
    sendInfo(url);
    return false;
  }

  // cause 1: org block list check
  ipInfo.org = ipInfo.org ? ipInfo.org.toLowerCase() : '';
  for (let org of config.blacklist) {
    if (ipInfo.org.indexOf(org) >= 0) {
      // this.pass = false;
      console.log('ORG In Black List, Quit');
      // send block info
      url += `&cause=1&org=${ipInfo.org}`;
      console.log(url);
      sendInfo(url);
      return false;
    }
  }

  // // cause 2: ios ua check
  // let ua = navigator.userAgent;
  // if (os == 'ios' && ua.indexOf('iPhone') < 0) {
  //   this.pass = false;
  //   url += `&cause=2&ua=${ua}`;
  //   console.log(url);
  //   sendInfo(url);
  //   return;
  // }

  // cause 3: timezone check
  let tz1 = getTimezoneOffset(ipInfo.timezone) / (3600 * 1000); //;
  let tz2 = new Date().getTimezoneOffset() / -60; // -minite
  if (Math.abs(tz1 - tz2) > 1) {
    // this.pass = false;
    url += `&cause=3&ip=${ipInfo.ip}&ti=${tz1}&tb=${tz2}`;
    console.log(url);
    sendInfo(url);
    return false;
  }

  // cause 4: language check
  let lang = navigator.language || '';
  lang = lang.toLowerCase();
  let validLang = false;
  for (let l of config.langList) {
    if (lang.indexOf(l) >= 0) {
      validLang = true;
      break;
    }
  }
  if (!validLang) {
    // this.pass = false;
    url += `&cause=4&l=${lang}`;
    console.log(url);
    sendInfo(url);
    return false;
  }

  // cause 5: country check
  let country = ipInfo.country || '';
  country = country.toLowerCase();
  if (!new Set(config.countryList).has(country)) {
    // this.pass = false;
    url += `&cause=5&c=${country}`;
    console.log(url);
    sendInfo(url);
    return false;
  }

  fetch('/trace');
  return true;


  // // cause 6: iphone check
  // let platform = navigator.platform;
  // if (os == 'ios' && platform != 'iPhone') {
  //   this.pass = false;
  //   url += `&cause=6&p=${platform}`;
  //   console.log(url);
  //   sendInfo(url);
  //   return;
  // }

  // // cause 7: webkit broswer check
  // if (os == 'ios') {
  //   try {
  //     let b = new BroadcastChannel(new Date());
  //     b.close();
  //     this.pass = false;
  //     url += `&cause=7`;
  //     console.log(url);
  //     sendInfo(url);
  //     return;
  //   } catch (e) {}
  // }

  // cause 11: check the request impression time difference
  // this verify can be disabled when 11th bits is 1 in verfiyDisableBit
  // if (os == 'ios' && !(adConfig.verifyDisableBit & (1 << 11))) {
  //   let time = new Date().getTime() / 1000;
  //   if (time - adConfig.reqtime > 30) {
  //     this.pass = false;
  //     url += `&cause=11`;
  //     console.log(url);
  //     fetch(url);
  //     // sendInfo(url);
  //   }
  //   return;
  // }
}


export default {
  verify
}