<template>
  <v-card class="rounded-card" style="background-color:#70BFE6">

    <v-list-item three-line>
      <v-list-item-avatar tile size="80">
        <!-- <v-img :src="game.thumb" height="100">
              </v-img> -->
        <!-- <v-card> -->
        <v-img :src="game.thumb" aspect-ratio="1" class="round-image">
        </v-img>
        <!-- </v-card> -->
      </v-list-item-avatar>

      <v-list-item-content>
        <!-- <v-list-item-title class="text-h5 mb-1"> -->
        <p class="font-weight-black text-center text-h5">
          {{ game.title }}
        </p>
        <!-- </v-list-item-title>
        <v-list-item-subtitle>
        </v-list-item-subtitle> -->
      </v-list-item-content>

    </v-list-item>

    <v-card-subtitle style="margin-top:-15px">
      <a :href="game.url" style="">
        <v-btn block large color="primary" dark height="40" class="text-h5" style="border-radius: 10px;">
          <v-img src="../assets/play.gif" max-height="25" max-width="25" style="margin-right:5px" transition="scroll-x-transition"></v-img>
          Play Now
        </v-btn>
      </a>
    </v-card-subtitle>


  </v-card>
</template>

<script>
export default {
  name: 'GameDetail',
  props: ['game'],

  data: () => ({
  }),
}
</script>


<style scoped>
.rounded-card {
  border-radius: 15px;
}

.round-image {
  border-radius: 15px;
  border: 2px solid #fff;
}
</style>