<template>
  <v-app dark style="background-color:#0073dd; ">
    <v-container flex>
      <v-row justify="space-around">
        <game-board></game-board>
      </v-row>
      <v-row justify="space-around">
        <p style="color:white" class="text-h7">Copyright @ 2022 AZURE TECH PTY LTD</p>
        <p style="color:white" class="text-h8">All Rights Reserved</p>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>

// import HelloWorld from './components/HelloWorld';
import GameBoard from './components/GameBoard.vue';

export default {
  name: 'App',

  components: {
    // HelloWorld,
    GameBoard
  },

  data: () => ({
    //
  }),
};
</script>

<styel scoped>
</styel>