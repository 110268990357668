import Vue from 'vue';
// import colors from 'vuetify/lib/util/colors'
import Vuetify from 'vuetify/lib/';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        // dark: true,
        themes: {
            dark: {
                primary: '#99C508',
                secondary: '#000',
                anchor: '#0073dd',
                info: '#fff'
            },
            light: {
                primary: '#99C508',
                secondary: '#21A97E',
            },
        },
    },
})